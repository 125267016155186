import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import parse from "html-react-parser"
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ProductAPI, AddressAPI } from "../services"
import { ProductList3 } from '../fragments'

SwiperCore.use([Autoplay, Pagination, Navigation])

function Home() {
  const [products, setProducts] = useState([]);
  const [addressValues, setAddressValues] = useState([]);

  useEffect(() => {
    let disposed = false
    if (disposed) return

    (async () => {
      const products = await ProductAPI.fetchProducts()
      setProducts(products)

      const addressValues = await AddressAPI.fetchAddressValues()
      setAddressValues(addressValues)
    })()

    return () => disposed = true
  }, []);

  return (
    <>
      <div className="w-full">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={true}
          navigation={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          loop={true}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide className="w-full mx-auto md:items-center">
            <div
              className="h-96 bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/swiper/all-product.jpg"
                })`,
              }}
            >
              <div className="flex h-full bg-blue-800 bg-opacity-75">
                <div className="m-auto">
                  <div className="h-full flex flex-col items-center tracking-wide inline-block align-middle">
                    <p className="text-6xl font-bold text-white">YOSHIJUNG</p>
                    <p className="text-2xl text-white">
                      YOSHIJUNG (THAILAND) CO.,LTD.
                    </p>
                    <p className="text-lg text-white">Fresh Frozen Foods</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full mx-auto md:items-center">
            <div
              className="h-96 bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/swiper/chicken-meat.jpg"
                })`,
              }}
            >
              <div className="container mx-auto">
                <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                  <p className="text-black text-2xl my-4 font-bold">
                    Checken meat
                  </p>
                  <NavLink
                    className="text-xl inline-block no-underline border-b border-gray-600 leading-relaxed hover:text-black hover:border-black"
                    to="/product/checken-meat"
                  >
                    view product
                  </NavLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full h-96">
            <div
              className="h-96 bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/swiper/pork.jpg"
                })`,
              }}
            >
              <div className="container mx-auto">
                <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                  <p className="text-black text-2xl my-4 font-bold">Pork</p>
                  <NavLink
                    className="text-xl inline-block no-underline border-b border-gray-600 leading-relaxed hover:text-black hover:border-black"
                    to="/product/pork"
                  >
                    view product
                  </NavLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="w-full h-96">
            <div
              className="h-96 bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/swiper/beef.jpg"
                })`,
              }}
            >
              <div className="container mx-auto">
                <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                  <p className="text-black text-2xl my-4 font-bold">Beef</p>
                  <NavLink
                    className="text-xl inline-block no-underline border-b border-gray-600 leading-relaxed hover:text-black hover:border-black"
                    to="/product/beef"
                  >
                    view product
                  </NavLink>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      
      {products &&
      <section className="bg-white py-2">
        <div className="container mx-auto flex items-center flex-wrap pt-4 pb-2">
          <nav id="store" className="w-full z-30 top-0 px-6 py-1">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
              <h1 className="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl">
                Featured Products
              </h1>

              <div className="flex items-center" id="store-nav-content">
                <NavLink className="text-gray-800 text-sm" to="/product">
                  See All
                </NavLink>

                {/* <a className="pl-3 inline-block no-underline hover:text-black" href="#">
                  <svg className="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M7 11H17V13H7zM4 7H20V9H4zM10 15H14V17H10z" />
                  </svg>
                </a>

                <a className="pl-3 inline-block no-underline hover:text-black" href="#">
                  <svg className="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M10,18c1.846,0,3.543-0.635,4.897-1.688l4.396,4.396l1.414-1.414l-4.396-4.396C17.365,13.543,18,11.846,18,10 c0-4.411-3.589-8-8-8s-8,3.589-8,8S5.589,18,10,18z M10,4c3.309,0,6,2.691,6,6s-2.691,6-6,6s-6-2.691-6-6S6.691,4,10,4z" />
                  </svg>
                </a> */}
              </div>
            </div>
          </nav>
        </div>

        {products && products.length > 0 &&
        <div className="container mx-auto px-6 pb-6">
          <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            <ProductList3 products={products} />
          </div>
        </div>
        }

      </section>
      }

      {addressValues &&
      <section className="bg-indigo-100 pt-2 pb-4">
        <div className="mx-auto w-full md:w-4/5 lg:w-4/6 xl:w-2/4">
          <nav id="store" className="w-full z-30 top-0 px-6 py-1">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
              <h1 id="transfer-information" className="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl inline-flex items-center py-2 leading-5">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" /></svg>
                &nbsp;Transfer information
              </h1>
            </div>
          </nav>
          <div className="pl-12">
            <table className="min-w-full">
              <tbody>
                {addressValues.map(({name, value}) => {
                  return <tr className="border-b" key={'address-' + name}>
                    <td className="py-2 pl-2 pr-6 text-sm font-medium text-gray-900 align-top">{name}</td>
                    <td className="py-2 px-6 text-sm text-gray-600 align-top">{parse(value)}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      }

      <section className="pt-6 pb-4">
        <div className="mx-auto w-full md:w-4/5 lg:w-4/6 xl:w-2/4">
          <nav id="store" className="w-full z-30 top-0 px-6 py-1">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
              <h1 id="contact" className="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl inline-flex items-center py-2 leading-5">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                &nbsp;Contact
              </h1>
            </div>
          </nav>
          <div className="pl-12">
            <h2 className="font-bold">Address</h2>
            <p className="font-sarabun">
              YOSHIJUNG (THAILAND) CO., LTD.
              <br />
              19/2 หมู่6 ซอยสี่ไชยทอง 23 ตำบลบางตลาด อำเภอปากเกร็ด นนทบุรี 11120
              <br />
              Tel. +66 21-053-224, +66 61-893-8886
            </p>
          </div>
        </div>
        <div className="w-full h-96 py-6 pb-3">
          <iframe
            title="Map"
            className="border-0 h-full w-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3872.9792270301327!2d100.52323521447401!3d13.900189797583344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2831028394edd%3A0xd72e4044b7de8a5d!2z4Lia4Lij4Li04Lip4Lix4LiXIOC5guC4ouC4iuC4tOC4iOC4seC4hyAo4Lib4Lij4Liw4LmA4LiX4Lio4LmE4LiX4LiiKSDguIjguLPguIHguLHguJQ!5e0!3m2!1sth!2sth!4v1642836327811!5m2!1sth!2sth" allowFullScreen="" loading="lazy"></iframe>
        </div>
      </section>

    </>
  );
}

export default Home;
