async function fetchAddressValues() {
  return [
    {
      name: 'BANK NAME',
      value: 'SIAM COMMERCIAL BANK PUBLIC COMPANY LIMITED',
    },
    {
      name: 'BANK ADDRESS',
      value: '35/2 MU2 NAGM WONGWAN RD,., BANGKHEN, MUEANG NONTHABURI 11000 THAILAND',
    },
    {
      name: 'ACCOUNT NAME',
      value: 'YOSHICHANG (THAILAND) CO., LTD.',
    },
    {
      name: 'SWIFT CODE',
      value: 'SICOTHBK',
    },
    {
      name: 'ACCOUNT NUMBER',
      value: `<div><span class="font-bold">319-298054-6</span> (THAI BAHT)</div>
      <div><span class="font-bold">319-200081-8-840</span> (US DOLLAR)</div>
      <div><span class="font-bold">319-200082-6-978</span> (EURO)</div>
      `,
    },
  ]
}

const api = {
  fetchAddressValues,
}

export default api
