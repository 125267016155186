import React from "react";

function Footer() {
  return (
    <footer className="bg-sky-900 w-full">
        <div className="px-3 py-4 text-center">
          <span className="text-white text-xs">COPYRIGHT &copy; 2021 YOSHIJUNG CO.,LTD</span>
            {/* <div className="w-full mx-auto flex flex-wrap">
                <div className="flex w-full lg:w-1/2 ">
                    <div className="px-3 md:px-0">
                        <h3 className="font-bold text-gray-900">About</h3>
                        <p className="py-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel mi ut felis tempus commodo nec id erat. Suspendisse consectetur dapibus velit ut lacinia.
                        </p>
                    </div>
                </div>
                <div className="flex w-full lg:w-1/2 lg:justify-end lg:text-right">
                    <div className="px-3 md:px-0">
                        <h3 className="font-bold text-gray-900">Social</h3>
                        <ul className="list-reset items-center pt-3">
                            <li>
                                <a className="inline-block no-underline hover:text-black hover:underline py-1" href="#">Add social links</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </div>
    </footer>
    // <div className="footer">
    //   <footer className="py-5 bg-dark fixed-bottom">
    //     <div className="container">
    //       <p className="m-0 text-center text-white">
    //         Copyright &copy; Your Website 2020
    //       </p>
    //     </div>
    //   </footer>
    // </div>
  );
}

export default Footer;
