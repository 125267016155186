import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import '@themesberg/flowbite'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Navigate } from 'react-router-dom'
import {
  Navigation,
  Footer,
  Home,
  // About,
  // Contact,
  // Blog,
  // Posts,
  // Post,
  ProductOutlet,
  Products,
  Product,
  Page404,
} from "./components";

ReactDOM.render(
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/product" element={<ProductOutlet />}>
        <Route path="" element={<Products />} />
        <Route path=":productSlug" element={<Product />} />
      </Route>
      <Route path='/404' element={<Page404 />} />
      <Route path='*' element={<Navigate replace to="/404" />} />
    </Routes>
    <Footer />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
