import React from "react";
import { NavLink } from 'react-router-dom'

function ProductList3(props) {
  const { products } = props

  return (
    <>
      {products.map(({productId, name, photo, slug}) => {
        return <div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden" key={'product-' + productId}>
          <NavLink
            className="flex items-end justify-end h-56 w-full bg-cover"
            style={{
              backgroundImage: `url('${photo}')`,
            }}
            to={process.env.PUBLIC_URL + '/product/' + slug}
          >
          </NavLink>
          <div className="px-5 py-3 text-center">
            <NavLink to={"/product/" + slug} className="text-center uppercase">
              <h3 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white">{name}</h3>
            </NavLink>
          </div>
        </div>
      })}
    </>
  );
}

export default ProductList3;
