import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom'
import { ProductAPI } from "../../services"
import { ProductList3 } from '../../fragments'

function Products() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    let disposed = false
    if (disposed) return

    (async () => {
      const products = await ProductAPI.fetchProducts()
      setProducts(products)
    })()

    return () => disposed = true
  }, [])

  return (
    <div className="min-h-screen">
      <div
        className="w-full h-32 bg-cover bg-center"
        style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/swiper/all-product.jpg"})`}}
      >
        <div className="flex h-full bg-neutral-800 bg-opacity-75">
          <div className="m-auto">
            <div className="h-full flex flex-col items-center tracking-wide inline-block align-middle">
              <h1 className="text-4xl font-bold text-white p-4">
                PRODUCTS
              </h1>
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li className="inline-flex items-center">
                    <NavLink
                      to={process.env.PUBLIC_URL + "/"}
                      className="inline-flex items-center text-sm text-slate-300 hover:text-slate-100"
                    >
                      <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                      Home
                    </NavLink>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                      <span className="ml-1 text-sm font-medium text-blue-300 md:ml-2">
                        Products
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-white py-8">
        <div className="container mx-auto px-6">

          {products && products.length > 0 &&
          <>
            <h3 className="text-gray-600 text-2xl font-medium">
              All Products
            </h3>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
              <ProductList3 products={products} />
            </div>
          </>
          }

        </div>

      </section>
    </div>
  );
}

export default Products;
