import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NavLink } from 'react-router-dom'
import { ProductAPI } from "../../services";

function Product() {
  const { productSlug } = useParams()
  const [product, setProduct] = useState(null);
  const [moreProducts, setMoreProducts] = useState([]);

  useEffect(() => {
    // Fetch post using the postSlug
    let disposed = false
    if (disposed) return

    (async () => {
      const product = await ProductAPI.fetchProductBySlug(productSlug)
      setProduct(product)

      const moreProducts = await ProductAPI.fetchMoreProducts(product.productId)
      setMoreProducts(moreProducts)
    })()

    return () => disposed = true
  }, [productSlug])

  function handlePhotoClick(url) {
    setProduct({...product, photo: url})
  }

  return (
    <>
      <div
        className="w-full h-32 bg-cover bg-center"
        style={{backgroundImage: `url(${process.env.PUBLIC_URL + "/swiper/all-product.jpg"})`}}
      >
        <div className="flex h-full bg-neutral-800 bg-opacity-75">
          <div className="m-auto">
            <div className="h-full flex flex-col items-center tracking-wide inline-block align-middle">
              <h1 className="text-4xl font-bold text-white p-4">
                PRODUCT DETAILS
              </h1>
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li className="inline-flex items-center">
                    <NavLink
                      to={process.env.PUBLIC_URL + "/"}
                      className="inline-flex items-center text-sm text-slate-300 hover:text-slate-100"
                    >
                      <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                      <NavLink
                        to={process.env.PUBLIC_URL + "/product"}
                        className="inline-flex items-center text-sm text-slate-300 hover:text-slate-100"
                      >
                        Products
                      </NavLink>
                    </div>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                      <span className="ml-1 text-sm font-medium text-blue-300 md:ml-2">
                        {product && product.name}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-white py-8">
        <div className="container mx-auto px-6">
          <div className="md:flex md:items-center">
            <div className="w-full h-64 md:w-1/2 lg:h-96">
              <div className="w-full h-full">
                <img
                  className="h-full w-full rounded-md object-cover  mx-auto"
                  src={product && product.photo}
                  alt={product && product.name}
                />
              </div>
            </div>
            <div className="w-full max-w-lg mx-auto mt-5 md:ml-8 md:mt-0 md:w-1/2">
              <h3 className="text-gray-700 uppercase text-xl font-bold">
                {product && product.name}
              </h3>
              {product && (
                <p className="text-gray-500 mt-3">{product.detail}</p>
              )}
              {/* <span className="text-gray-500 mt-3">$125</span>
              <hr className="my-3" />
              <div className="mt-2">
                <label className="text-gray-700 text-sm" for="count">Count:</label>
                <div className="flex items-center mt-1">
                  <button className="text-gray-500 focus:outline-none focus:text-gray-600">
                    <svg className="h-5 w-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                  </button>
                  <span className="text-gray-700 text-lg mx-2">20</span>
                  <button className="text-gray-500 focus:outline-none focus:text-gray-600">
                    <svg className="h-5 w-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                  </button>
                </div>
              </div> */}
              {/* <div className="mt-3">
                <label className="text-gray-700 text-sm" for="count">Color:</label>
                <div className="flex items-center mt-1">
                  <button className="h-5 w-5 rounded-full bg-blue-600 border-2 border-blue-200 mr-2 focus:outline-none"></button>
                  <button className="h-5 w-5 rounded-full bg-teal-600 mr-2 focus:outline-none"></button>
                  <button className="h-5 w-5 rounded-full bg-pink-600 mr-2 focus:outline-none"></button>
                </div>
              </div>
              <div className="flex items-center mt-6">
                <button className="px-8 py-2 bg-indigo-600 text-white text-sm font-medium rounded hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">Order Now</button>
                <button className="mx-2 text-gray-600 border rounded-md p-2 hover:bg-gray-200 focus:outline-none">
                  <svg className="h-5 w-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
                </button>
              </div> */}
            </div>
          </div>

          {product && product.photos && product.photos.length > 0 &&
          <div className="mt-4">
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
              {product.photos.map(({url, caption}, i) => {
                const photoKey = 'photo-' + i;
                const linkUrl = `${process.env.PUBLIC_URL}/product/${product.slug}#${photoKey}`
                return <div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden" key={photoKey}>
                  <NavLink
                    to={linkUrl}
                    value={url}
                    onClick={() => { handlePhotoClick(url) }}
                    className="flex items-end justify-end h-56 w-full bg-cover"
                    style={{
                      backgroundImage: `url('${url}')`,
                    }}
                  >
                  </NavLink>
                  <div className="px-5 py-3 text-center">
                    <NavLink to={linkUrl} value={url} onClick={() => { handlePhotoClick(url) }}>
                      <h3 className="text-gray-700 uppercase">{caption}</h3>
                    </NavLink>
                  </div>
                </div>
              })}
            </div>
          </div>
          }

          {moreProducts && moreProducts.length > 0 &&
          <div className="mt-16">
            <h3 className="text-gray-600 text-2xl font-medium">
              More Products
            </h3>
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
              {moreProducts.map(({productId, name, photo, slug}) => {
                return <div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden" key={'more-product-' + productId}>
                  <NavLink
                    className="flex items-end justify-end h-56 w-full bg-cover"
                    style={{
                      backgroundImage: `url('${photo}')`,
                    }}
                    to={process.env.PUBLIC_URL + '/product/' + slug}
                  >
                  </NavLink>
                  <div className="px-5 py-3 text-center">
                    <NavLink to={process.env.PUBLIC_URL + '/product/' + slug} className="text-gray-700 uppercase">{name}</NavLink>
                  </div>
                </div>
              })}
            </div>
          </div>
          }
        </div>

      </section>
    </>
  );
}

export default Product;
