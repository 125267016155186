async function fetchProducts() {
  return [
    {
      productId: 1,
      name: 'Chicken meat',
      photo: process.env.PUBLIC_URL + '/product/chicken-meat/main.png', // 920x613
      photos: [
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/chicken-leg-quarter-with-back-bone.jpg',
          caption: 'Chicken Leg Quarter with Back Bone'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/mid-joint-wing.jpg',
          caption: 'Mid Joint Wing'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/drumette.jpg',
          caption: 'Drumette'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/thigh.jpg',
          caption: 'Thigh'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/boneless-breast.jpg',
          caption: 'Boneless breast'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/drumstick.jpg',
          caption: 'Drumstick'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/wing-tip.jpg',
          caption: 'Wing tip'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/feet.jpg',
          caption: 'Feet'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/fillet.jpg',
          caption: 'Fillet'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/liver.jpg',
          caption: 'Liver'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/heart.jpg',
          caption: 'Heart'
        },
        {
          url: process.env.PUBLIC_URL + '/product/chicken-meat/gizzard.jpg',
          caption: 'Gizzard'
        },
      ],
      slug: 'chicken-meat',
      detail: `Our meat chickens are raised in barns on our farm. They are
      raised as humanely as possible; not overcrowded, never fed
      hormones or antibiotics. They are bedded down with kiln dried
      sawdust twice daily to keep them dry and healthy. We do all
      chicken processing here at the farm and they are processed as
      humanely as possible. Since we are only able to process limited
      numbers of chickens, you are guaranteed the freshest, best
      tasting chicken around.`,
    },
    {
      productId: 2,
      name: 'Pork',
      photo: process.env.PUBLIC_URL + '/product/pork/main.png',
      photos: [
        {
          url: process.env.PUBLIC_URL + '/product/pork/tenderloin.jpg',
          caption: 'Tenderloin'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/loin.jpg',
          caption: 'Loin'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/collar.jpg',
          caption: 'Collar'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/shoulder.jpg',
          caption: 'Shoulder'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/ham.jpg',
          caption: 'Ham'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/bacon.jpg',
          caption: 'Bacon'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/ribs.jpg',
          caption: 'Ribs'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/head.jpg',
          caption: 'Head'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/leg.jpg',
          caption: 'Leg'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/heart.jpg',
          caption: 'Heart'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/liver.jpg',
          caption: 'Liver'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/kidney.jpg',
          caption: 'Kidney'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/intestine.jpg',
          caption: 'Intestine'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/lung.jpg',
          caption: 'Lung'
        },
        {
          url: process.env.PUBLIC_URL + '/product/pork/tongue.jpg',
          caption: 'Tongue'
        },
      ],
      slug: 'pork',
      detail: `Pasture Raised, Antibiotic and Hormone Free! Pork the way it
      should be. Our pigs get to root around in the dirt, splash in
      puddles and take mud baths whenever they want and sleep in beds
      of warm cozy hay.`,
    },
    {
      productId: 3,
      name: 'Beef',
      photo: process.env.PUBLIC_URL + '/product/beef/main.png',
      photos: [
        {
          url: process.env.PUBLIC_URL + '/product/beef/brisket.jpg',
          caption: 'Brisket'
        },
        {
          url: process.env.PUBLIC_URL + '/product/beef/sirloin.jpg',
          caption: 'Sirloin'
        },
        {
          url: process.env.PUBLIC_URL + '/product/beef/chuck.jpg',
          caption: 'Chuck '
        },
        {
          url: process.env.PUBLIC_URL + '/product/beef/flank.jpg',
          caption: 'Flank'
        },
        {
          url: process.env.PUBLIC_URL + '/product/beef/round.jpg',
          caption: 'Round'
        },
        {
          url: process.env.PUBLIC_URL + '/product/beef/shank.jpg',
          caption: 'Shank'
        },
        {
          url: process.env.PUBLIC_URL + '/product/beef/short-ribs.jpg',
          caption: 'Short ribs'
        },
      ],
      slug: 'beef',
      detail: `Our cows are fed with hay, extra veggies,
      sweet corn stalks and a small amount
      of GMO-free grain to help marble the meat for the best final
      product. No hormones or antibiotics are needed raising our
      animals the natural way!`,
    }
  ]
}

async function fetchProductById(id) {
  const products = await fetchProducts()

  return products.find(({ productId }) => productId === id)
}

async function fetchProductBySlug(inputSlug) {
  const products = await fetchProducts()

  return products.find(({ slug }) => slug === inputSlug)
}

async function fetchMoreProducts(id) {
  const products = await fetchProducts()

  return products.filter(({ productId }) => id !== productId)
}

const api = {
  fetchProducts,
  fetchProductById,
  fetchProductBySlug,
  fetchMoreProducts,
}

export default api
